import {
  APP_BASE_HREF,
  AsyncPipe,
  CommonModule,
  PlatformLocation,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SharedRoutingModule } from './shared-routing.module';
import { AddCommissionModalComponent } from './components/add-commission-modal/add-commission-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProposalsComponent } from './components/proposals/proposals.component';
import { LeadFormComponent } from './components/lead-form/lead-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ToastrModule } from 'ngx-toastr';
import { HealthFormComponent } from './components/lead-form/health-form/health-form.component';
import { TravelFormComponent } from './components/lead-form/travel-form/travel-form.component';
import { CentralisationFormComponent } from './components/lead-form/centralisation-form/centralisation-form.component';
import { RcHouseholdFormComponent } from './components/lead-form/rc-household-form/rc-household-form.component';
import { VehicleFormComponent } from './components/lead-form/vehicle-form/vehicle-form.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LegalProtectionFormComponent } from './components/lead-form/legal-protection-form/legal-protection-form.component';
import { BuildingFormComponent } from './components/lead-form/building-form/building-form.component';
import { ConstructionFormComponent } from './components/lead-form/construction-form/construction-form.component';
import { ThirdPillarFormComponent } from './components/lead-form/third-pillar-form/third-pillar-form.component';
import { AnimalFormComponent } from './components/lead-form/animal-form/animal-form.component';
import { ValuableObjectsFormComponent } from './components/lead-form/valuable-objects-form/valuable-objects-form.component';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentUploadModalComponent } from './components/document-upload-modal/document-upload-modal.component';
import { GenerateUserPasswordModalComponent } from './components/generate-user-password-modal/generate-user-password-modal.component';
import { UpdateLeadComponent } from './components/update-lead/update-lead.component';
import { AddLeadModalComponent } from './components/add-lead-modal/add-lead-modal.component';
import { DocumentRequestModalComponent } from './components/document-request-modal/document-request-modal.component';
import { UpdateDocumentModalComponent } from './components/update-document-modal/update-document-modal.component';
import { UpdatePolicyStatusComponent } from './components/update-policy-status/update-policy-status.component';
import { AssignProductsModalComponent } from './components/assign-products-modal/assign-products-modal.component';
import { InitCommissionButtonComponent } from './components/init-commission-button/init-commission-button.component';
import { CancellationFormComponent } from './components/lead-form/cancellation-form/cancellation-form.component';
import { PartnerNavigationComponent } from './components/partner-navigation/partner-navigation.component';
import { AgentNavigationComponent } from './components/agent-navigation/agent-navigation.component';
import { PartnerActivationModalComponent } from './components/partner-activation-modal/partner-activation-modal.component';
import { AssignPartnerAgentModalComponent } from './components/assign-partner-agent-modal/assign-partner-agent-modal.component';
import { AssignCustomerSupportModalComponent } from './components/assign-customer-support-modal/assign-customer-support-modal.component';
import { MatInputModule } from '@angular/material/input';
import { DuplicateLeadComponent } from './components/duplicate-lead/duplicate-lead.component';
import { DeleteLeadModalComponent } from './components/delete-lead-modal/delete-lead-modal.component';
import { DeleteProposalModalComponent } from './components/delete-proposal-modal/delete-proposal-modal.component';
import { IconsModule } from './icons/icons.module';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { SlideInModalComponent } from './components/slide-in-modal/slide-in-modal.component';
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { FloatingButtonsComponent } from './components/floating-buttons/floating-buttons.component';
import { FileSizePipe } from '../_pipes/file-size.pipe';
import { ActionModalComponent } from './components/action-modal/action-modal.component';
import { CommissionBaseListComponent } from './components/commission-base-list/commission-base-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { AssignProductSlideModalComponent } from './components/assign-product-slide-modal/assign-product-slide-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { ButtonComponent as CommissionListButtonComponent } from './components/commission-base-list/button/button.component';
import { DonutChartComponent } from './components/charts/donut-chart/donut-chart.component';
import { GridCopyButtonComponent } from './components/grid-copy-button/grid-copy-button.component';
import { DateTimePipe } from '../_pipes/date-time.pipe';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { ButtonLeadComponent } from './components/tasks-list/button-lead/button-lead.component';
import { MatMenuModule } from '@angular/material/menu';
import { ProposalDetailsComponent } from './components/proposal-details/proposal-details.component';
import { LeadDetailsComponent } from './components/lead-details/lead-details.component';
import { CancellationMetadataComponent } from './components/lead-details/metadata-components/cancellation-metadata/cancellation-metadata.component';
import { ThirdPillarMetadataComponent } from './components/lead-details/metadata-components/third-pillar-metadata/third-pillar-metadata.component';
import { ValuableObjectsMetadataComponent } from './components/lead-details/metadata-components/valuable-objects-metadata/valuable-objects-metadata.component';
import { ConstructionMetadataComponent } from './components/lead-details/metadata-components/construction-metadata/construction-metadata.component';
import { VehicleMetadataComponent } from './components/lead-details/metadata-components/vehicle-metadata/vehicle-metadata.component';
import { HouseholdMetadataComponent } from './components/lead-details/metadata-components/household-metadata/household-metadata.component';
import { LegalProtectionMetadataComponent } from './components/lead-details/metadata-components/legal-protection-metadata/legal-protection-metadata.component';
import { AnimalMetadataComponent } from './components/lead-details/metadata-components/animal-metadata/animal-metadata.component';
import { BuildingMetadataComponent } from './components/lead-details/metadata-components/building-metadata/building-metadata.component';
import { TravelMetadataComponent } from './components/lead-details/metadata-components/travel-metadata/travel-metadata.component';
import { HealthMetadataComponent } from './components/lead-details/metadata-components/health-metadata/health-metadata.component';
import { CentralisationMetadataComponent } from './components/lead-details/metadata-components/centralisation-metadata/centralisation-metadata.component';
import { ClaimDetailsComponent } from './components/claim-details/claim-details.component';
import { AddProposalModalComponent } from './components/add-proposal-modal/add-proposal-modal.component';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { TagInputModule } from 'ngx-chips';
import { ViewProposalsComponent } from './components/view-proposals/view-proposals.component';

//import { CustomerNavComponent } from './components/customer-nav/customer-nav.component';
//import { DataAnalysisNavComponent } from './components/data-analysis-nav/data-analysis-nav.component';
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    AddCommissionModalComponent,
    ProposalsComponent,
    HealthFormComponent,
    TravelFormComponent,
    CentralisationFormComponent,
    RcHouseholdFormComponent,
    VehicleFormComponent,
    LegalProtectionFormComponent,
    BuildingFormComponent,
    ConstructionFormComponent,
    ThirdPillarFormComponent,
    AnimalFormComponent,
    ValuableObjectsFormComponent,
    PdfViewerModalComponent,
    DocumentUploadModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateLeadComponent,
    LeadFormComponent,
    AddLeadModalComponent,
    DocumentRequestModalComponent,
    UpdateDocumentModalComponent,
    UpdatePolicyStatusComponent,
    AssignProductsModalComponent,
    InitCommissionButtonComponent,
    CancellationFormComponent,
    PartnerNavigationComponent,
    AgentNavigationComponent,
    PartnerActivationModalComponent,
    AssignPartnerAgentModalComponent,
    AssignCustomerSupportModalComponent,
    DuplicateLeadComponent,
    DeleteLeadModalComponent,
    DeleteProposalModalComponent,
    BaseLayoutComponent,
    SlideInModalComponent,
    TabNavigationComponent,
    FloatingButtonsComponent,
    FileSizePipe,
    ActionModalComponent,
    CommissionBaseListComponent,
    AssignProductSlideModalComponent,
    CommissionListButtonComponent,
    DonutChartComponent,
    GridCopyButtonComponent,
    DateTimePipe,
    BarChartComponent,
    TasksListComponent,
    ButtonLeadComponent,
    ProposalDetailsComponent,
    LeadDetailsComponent,
    CancellationMetadataComponent,
    ThirdPillarMetadataComponent,
    ValuableObjectsMetadataComponent,
    ConstructionMetadataComponent,
    VehicleMetadataComponent,
    HouseholdMetadataComponent,
    LegalProtectionMetadataComponent,
    AnimalMetadataComponent,
    BuildingMetadataComponent,
    TravelMetadataComponent,
    HealthMetadataComponent,
    CentralisationMetadataComponent,
    ClaimDetailsComponent,
    AddProposalModalComponent,
    ViewProposalsComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ToastrModule,
    MatAutocompleteModule,
    NgbModalModule,
    PdfViewerModule,
    AsyncPipe,
    MatInputModule,
    IconsModule,
    AgGridModule,
    MatIconModule,
    MatChipsModule,
    MatMenuModule,
    TagInputModule,
    EditorComponent,
  ],
  exports: [
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    AddCommissionModalComponent,
    ProposalsComponent,
    PdfViewerModalComponent,
    DocumentUploadModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateLeadComponent,
    AddLeadModalComponent,
    DocumentRequestModalComponent,
    UpdateDocumentModalComponent,
    UpdatePolicyStatusComponent,
    AssignProductsModalComponent,
    InitCommissionButtonComponent,
    PartnerNavigationComponent,
    AgentNavigationComponent,
    PartnerActivationModalComponent,
    AssignPartnerAgentModalComponent,
    AssignCustomerSupportModalComponent,
    DuplicateLeadComponent,
    DeleteLeadModalComponent,
    DeleteProposalModalComponent,
    IconsModule,
    BaseLayoutComponent,
    SlideInModalComponent,
    TabNavigationComponent,
    FloatingButtonsComponent,
    FileSizePipe,
    ActionModalComponent,
    CommissionBaseListComponent,
    AssignProductSlideModalComponent,
    DonutChartComponent,
    GridCopyButtonComponent,
    DateTimePipe,
    BarChartComponent,
    TasksListComponent,
    ButtonLeadComponent,
    ProposalDetailsComponent,
    LeadDetailsComponent,
    CancellationMetadataComponent,
    ThirdPillarMetadataComponent,
    ValuableObjectsMetadataComponent,
    ConstructionMetadataComponent,
    VehicleMetadataComponent,
    HouseholdMetadataComponent,
    LegalProtectionMetadataComponent,
    AnimalMetadataComponent,
    BuildingMetadataComponent,
    TravelMetadataComponent,
    HealthMetadataComponent,
    CentralisationMetadataComponent,
    ClaimDetailsComponent,
    AddProposalModalComponent,
    ViewProposalsComponent,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
})
export class SharedModule {}
