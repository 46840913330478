import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css'],
})
export class HomeLayoutComponent {
  @HostBinding('style.display') public display = 'flex';
  @HostBinding('style.flexDirection') public flexDirection = 'column';
  @HostBinding('style.height') public height = '100%';
}
