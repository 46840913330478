import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-double-chevron-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66669 9.99999L8.00002 13.3333L11.3334 9.99999M4.66669 5.99999L8.00002 2.66666L11.3334 5.99999"
        [attr.stroke]="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class DoubleChevronIcon extends BaseIconComponent {}
