import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-logout-icon',
  template: `<svg
    [attr.width]="width"
    [attr.height]="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 11.3336L14 8.00027M14 8.00027L10.6667 4.66694M14 8.00027H6M8 11.3336C8 11.9536 8 12.2636 7.93185 12.5179C7.74692 13.2081 7.20782 13.7472 6.51764 13.9321C6.26331 14.0003 5.95332 14.0003 5.33333 14.0003H5C4.06812 14.0003 3.60218 14.0003 3.23463 13.848C2.74458 13.645 2.35523 13.2557 2.15224 12.7656C2 12.3981 2 11.9322 2 11.0003V5.00027C2 4.06839 2 3.60245 2.15224 3.23491C2.35523 2.74485 2.74458 2.3555 3.23463 2.15252C3.60218 2.00027 4.06812 2.00027 5 2.00027H5.33333C5.95332 2.00027 6.26331 2.00027 6.51764 2.06842C7.20782 2.25336 7.74692 2.79245 7.93185 3.48264C8 3.73697 8 4.04696 8 4.66694"
      [attr.stroke]="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> `,
})
export class LogoutIcon extends BaseIconComponent {}
