import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-user-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 14.0003C13.3333 13.0699 13.3333 12.6047 13.2185 12.2262C12.96 11.3739 12.293 10.707 11.4408 10.4484C11.0622 10.3336 10.597 10.3336 9.66665 10.3336H6.33333C5.40295 10.3336 4.93776 10.3336 4.55923 10.4484C3.70696 10.707 3.04002 11.3739 2.78148 12.2262C2.66666 12.6047 2.66666 13.0699 2.66666 14.0003M11 5.00027C11 6.65713 9.65684 8.00027 7.99999 8.00027C6.34314 8.00027 4.99999 6.65713 4.99999 5.00027C4.99999 3.34342 6.34314 2.00027 7.99999 2.00027C9.65684 2.00027 11 3.34342 11 5.00027Z"
        [attr.stroke]="color"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class UserIcon extends BaseIconComponent {}
