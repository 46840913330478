import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-letter-management-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3599 20.1004H16.5599C18.2399 20.1004 19.5199 18.7404 19.5199 17.1404V10.0204C19.5199 9.54039 19.1999 9.22039 18.7199 9.22039H15.1999V1.70039C15.1999 1.30039 14.7999 0.900391 14.3999 0.900391H1.1999C0.719902 0.900391 0.399902 1.30039 0.399902 1.70039V17.1404C0.399902 17.9404 0.719902 18.7404 1.2799 19.3004C1.8399 19.8604 2.5599 20.1004 3.3599 20.1004ZM15.1999 10.8204H17.9999V17.1404C17.9999 17.9404 17.3599 18.5004 16.6399 18.5004C15.9199 18.5004 15.2799 17.8604 15.2799 17.1404V10.8204H15.1999ZM4.1599 4.10039H11.1199C11.5999 4.10039 11.9199 4.50039 11.9199 4.90039C11.9199 5.30039 11.5999 5.70039 11.1199 5.70039H4.1599C3.6799 5.70039 3.3599 5.30039 3.3599 4.90039C3.3599 4.50039 3.6799 4.10039 4.1599 4.10039ZM4.1599 7.78039H11.1199C11.5999 7.78039 11.9199 8.18039 11.9199 8.58039C11.9199 9.06039 11.5999 9.38039 11.1199 9.38039H4.1599C3.6799 9.38039 3.3599 9.06039 3.3599 8.58039C3.3599 8.18039 3.6799 7.78039 4.1599 7.78039ZM4.1599 11.5404H11.1199C11.5999 11.5404 11.9199 11.9404 11.9199 12.3404C11.9199 12.7404 11.5999 13.1404 11.1199 13.1404H4.1599C3.6799 13.1404 3.3599 12.7404 3.3599 12.3404C3.3599 11.9404 3.6799 11.5404 4.1599 11.5404ZM4.1599 15.3004H11.1199C11.5999 15.3004 11.9199 15.6204 11.9199 16.1004C11.9199 16.5004 11.5999 16.9004 11.1199 16.9004H4.1599C3.6799 16.9004 3.3599 16.5004 3.3599 16.1004C3.3599 15.7004 3.6799 15.3004 4.1599 15.3004Z"
        [attr.fill]="color"
      />
    </svg>
  `,
})
export class LetterManagementIcon extends BaseIconComponent {}
