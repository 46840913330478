<div class="hover-dropdown">
  <img src="assets/images/{{ countryFlag }}" alt="" />
  <span>{{customLabels[selectedCountryCode]}}</span>
</div>
<ul class="language-popup">
  <li (click)="languageSwitcherAction('en', 'en.svg')">
    <a class="dropdown-item" href="javascript:void(0);">
      <img i18n-alt src="assets/images/en.svg" alt="USA" />
      <span i18n>English</span>
    </a>
  </li>
  <li (click)="languageSwitcherAction('fr', 'fr.svg')">
    <a class="dropdown-item" href="javascript:void(0);">
      <img i18n-alt src="assets/images/fr.svg" alt="France" />
      <span i18n>Français</span>
    </a>
  </li>
</ul>