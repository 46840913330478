<footer class="d-flex flex-column footer">
  <div class="d-flex flex-column">
    <span class="text-center text-14 color-white weight-400" i18n>LTY est disponible aussi sur Google play et
      AppStore</span>
    <div class="mt-16 d-flex justify-content-center" id="storeDiv">
      <a href="https://play.google.com/store/apps/details?id=ch.lty.client" target="_blank">
        <img src="assets/images/playstore-badge.svg" />
      </a>
      <a href="https://apps.apple.com/fr/app/lty/id6503351226" target="_blank">
        <img src="assets/images/appstore-badge.svg" />
      </a>
    </div>
  </div>
  <hr class="mt-40 mb-46">
  <div class="d-flex flex-column align-items-center">
    <img class="mb-8" src="assets/images/logo-white.svg" width="49px" height="36px" />
    <p class="mb-46 text-center color-brand-25 weight-400" i18n>
      LTY agit en tant qu'intérmédiaire en assurance non-lié. Les intermédiaires d’assurance non liés entretiennent des
      rapports de loyauté avec leur clientèle et agissent dans son intérêt. Ils ne sont pas autorisés à s’engager dans
      une collaboration avec une entreprise d’assurance qui limiterait leur indépendance et ne peuvent pas non plus
      représenter une entreprise d’assurance.
    </p>
    <div class="mb-46 w-100 d-flex justify-content-between" id="mainFooterDiv">
      <div class="d-flex flex-column color-white weight-400">
        <span i18n>Chemin Saint-Hubert 40</span>
        <span i18n>1950 Sion</span>
        <a class="text-decoration-underline color-white" href="mailto:info@lty.ch"><span>info@lty.ch</span></a>
        <span i18n>027 203 81 13</span>
      </div>
      <div class="footer-links">
        <a href="https://www.lty.ch/#discover-services" target="_blank" i18n>Nos services</a>
        <a href="https://www.lty.ch/#why-choose-lty" target="_blank" i18n>Pourquoi nous choisir ?</a>
        <a href="https://www.lty.ch/#faq" target="_blank" i18n>FAQ</a>
        <a i18n>Blog</a>
      </div>
      <div class="d-flex" id="socialsDiv">
        <a href="https://www.facebook.com/ltyassurances" target="_blank">
          <img width="24px" height="auto" src="assets/images/facebook.svg" />
        </a>
        <a href="https://www.instagram.com/lty.ch/" target="_blank">
          <img width="24px" height="auto" src="assets/images/instagram.svg" />
        </a>
        <a href="https://www.linkedin.com/company/lty/" target="_blank">
          <img width="24px" height="auto" src="assets/images/linkedin.svg" />
        </a>
        <a href="https://www.tiktok.com/@lty.ch" target="_blank">
          <img width="24px" height="auto" src="assets/images/tiktok.svg" />
        </a>
      </div>
    </div>
    <span class="text-center color-brand-25 weight-400" i18n>LTY est inscrite au Registre du Commerce du Valais Central
      et au Registre des
      Intermédiaires en
      Assurances auprès de la FINMA F01310986.</span>
  </div>
</footer>